div.sitewide-footer-formatter {
  &__country-chooser {
    float: none;
    @include breakpoint($landscape-up) {
      margin: 0;
    }
  }
  &__trustmark {
    display: none;
    &-wrapper {
      &.trustmark-logo-wrapper {
        display: block;
        margin: 0 auto;
        order: 3;
        text-align: center;
        width: auto;
        @include breakpoint($medium-up) {
          margin-top: 40px;
        }
      }
      img {
        width: 150px;
        margin: 56px 0;
        @include breakpoint($medium-up) {
          margin: 0;
        }
      }
    }
  }
  &__terms-links {
    &.footer-links {
      margin: 0;
    }
  }
  .footer-country-link {
    @include breakpoint($landscape-up) {
      width: 100px;
    }
  }
}
