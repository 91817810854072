#pr-war-form > div {
  &.form-group {
    &.pr-bestuses-form-group {
      display: none;
    }
  }
}

.write_a_review__container {
  #pr-write {
    .pr-war {
      .pr-submit {
        .pr-footer {
          float: $ldirection;
          .pr-subscript {
            display: none;
          }
        }
      }
    }
  }
}
