@font-face {
  font-family: 'Nanum Myeongjo';
  src: local('Nanum Myeongjo Regular'), local('Nanum-Myeongjo-Regular'),
    url('/_fonts/jomalone2/cjk/Nanum_Myeongjo/nanum-myeongjo-v15-korean-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: local('Noto Sans KR Regular'), local('Noto-Sans-KR-Regular'),
    url('/_fonts/jomalone2/cjk/NotoSans/NotoSansCJKkr-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Typo_SSiGothic 140';
  src: local('Typo_SSiGothic 140 Regular'), local('Typo_SSiGothic-140-Regular'),
    url('/_fonts/jomalone2/cjk/typo_SSigothic/Typo_SSiGothic 140.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
