div.signin-active {
  .gnav-account {
    &__content-container {
      &__toggle {
        &-signin {
          cursor: auto;
          text-decoration: none;
        }
      }
    }
  }
}
