.cs-contact-form-formatter {
  &__fieldset {
    &.address1,
    &.address2,
    &.state,
    &.city,
    &.last_name,
    &.postal_code {
      display: none;
    }
    &.first_name,
    &.phone_number {
      width: 100%;
    }
    &.order-number {
      display: block !important;
      width: 100%;
      input,
      label {
        visibility: visible;
      }
    }
    &.my_message {
      .field {
        + .adpl-label::before {
          position: relative;
          top: 50px;
        }
      }
    }
  }
}
